import React from 'react'
import CALLCENTRE from "../../../assets/image/callcentre.jpg"
import "./hero.css"
import { Link } from "react-router-dom/cjs/react-router-dom"

const CallCentre = () => {
  return (
    <div className='w-full flex h-screen items-start herocallcet'>
        <div className='relative w-full md:w-1/2 h-full flex flex-col mx-12'>
           <div className='absolute top-{50%} left-{6%} flex flex-col my-8 textmargn'>
           
           <h1 className='text-4xl md:text-6xl text-black md:text-gray-600 font-bold text-center md:pl-15'>CALL CENTRE, MLIMI HOTLINE</h1>
           <p className='text-4xl text-black folt-normal text-center md:px-10 md:py-10'>
            Mlimi Hotline, is one of the immediate, effective and real time extension service platforms owned by Farm Radio Holdings (FHL). Mlimi Hotline provides demand-driven agricultural extension advisory services through toll free lines, accessible on Airtel line on 8111, and TNM line on 7111.
         
           </p>
           <div className="divbutton flex">
            <Link to="/services">
                <button className=' mx-6'>
                <i class="fa fa-phone"></i>Call US Now
            </button>
            <button className='btn1 mx-6 text-black'>
                <i className='fa fa-envelope-open'></i>Read More
            </button>
            </Link>
            </div>
           </div> 
           
        </div>
      
    </div>
  )
}

export default CallCentre
