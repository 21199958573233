import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import "./hero.css"
import Welcome from './Welcome';
import CallCentre from './CallCentre';
import Mobile from './Mobile';
import Radio from './Radio';
import Messages from './Messages';




const Hero = () => {

  return (
    <>
      <section >

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 12000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >


        <SwiperSlide ><Welcome/></SwiperSlide>
        <SwiperSlide > <Mobile/> </SwiperSlide>
        <SwiperSlide ><CallCentre/></SwiperSlide>
        <SwiperSlide><Radio/></SwiperSlide>
        
        <SwiperSlide><Messages/></SwiperSlide>

      </Swiper>
       
      </section>
    </>
  )
}

export default Hero
