import React from 'react'

const ExecutiveCard = () => {
  return (
    <>
    <section id="exteam" className="exteam section-bg">
    
    <div className="container">

      <div className="section-title" data-aos="fade-up">
        <h1>Our <strong>Executive Team</strong></h1>
        </div>

      <div className="row chief">

        <div className="">
          <div className="member" data-aos="fade-up">
            <div className="member-img">
              <img src="./image/img/team/team-1.jpg" className="img-fluid" alt=""/>
              <div className="social">
                <a href=""><i className="bi bi-twitter"></i></a>
                <a href=""><i className="bi bi-facebook"></i></a>
                <a href=""><i className="bi bi-instagram"></i></a>
                <a href=""><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div className="member-info">
              <h4>George Vilili</h4>
              <span>Chief Executive Officer(CEO)</span>
            </div>
          </div>
        </div>


      </div>
      <div className="row ">

      <div className="col-lg-3 col-md-6 d-flex align-items-stretch left padding">
        <div className="member" data-aos="fade-up">
          <div className="member-img">
            <img src="./image/img/team/team-2.jpg" className="img-fluid" alt=""/>
            <div className="social">
              <a href=""><i className="bi bi-twitter"></i></a>
              <a href=""><i className="bi bi-facebook"></i></a>
              <a href=""><i className="bi bi-instagram"></i></a>
              <a href=""><i className="bi bi-linkedin"></i></a>
            </div>
          </div>
          <div className="member-info">
            <h4>Flora Khomani</h4>
            <span>Director of Programs (DoP)</span>
          </div>
        </div>
      </div>



      <div className="col-lg-3 col-md-6 d-flex align-items-stretch right padding">
      <div className="member" data-aos="fade-up">
        <div className="member-img">
          <img src="./image/img/team/team-3.jpg" className="img-fluid" alt=""/>
          <div className="social">
            <a href=""><i className="bi bi-twitter"></i></a>
            <a href=""><i className="bi bi-facebook"></i></a>
            <a href=""><i className="bi bi-instagram"></i></a>
            <a href=""><i className="bi bi-linkedin"></i></a>
          </div>
        </div>
        <div className="member-info">
          <h4>Mercius Mbewe</h4>
          <span>Director of Finance & Administration(DOF)</span>
        </div>
      </div>
    </div>

    </div>

    </div>
  </section>
    
    </>
  )
}

export default ExecutiveCard