import React from "react"
import img from "../images/services.jpg"
import Back from "../common/Back"
import "../home/featured/Featured.css"
import Service from "./service/Service"
import Wedo from "./Wedo/Wedo"
import Header from "../common/header/Header"


const Services = () => {
  return (
    <>
    <Header/>
      <section className='services mb'>
        <Back name='Services' title='Services -All Services' cover={img} />
        <Wedo/>
        <Service/>
     </section>
    </>
  )
}

export default Services
