import React from 'react'
import Back from '../../common/Back'
import img from "../../images/team.jpg"
import "./staff.css"
import { ictteam } from '../../data/Data'
import { team } from '../../data/Data'
import { admin } from '../../data/Data'
import Heading from '../../common/Heading'
import { programs } from '../../data/Data'
import { radio } from '../../data/Data'
import { transport } from '../../data/Data'
import { content } from '../../data/Data'
import ExecutiveCard from '../Executive/ExecutiveCard'
import Header from '../../common/header/Header'

const Staff = () => {
  return (
    <>
    <Header/>
    <Back name='The Staff Members for FRT & FHL' title='FRT -The Staff' cover={img} />
    <ExecutiveCard/>
    <section className='staffteam background'>
    <div className='container'>
      <Heading title='Finance & Administration' subtitle='' />

      <div className='content mtop grid4'>
        {admin.map((val, index) => (
          <div className='box' key={index}>
           
            <div className='details'>
              <div className='img'>
                <img src={val.cover} alt='' />
               
              </div>
              
              <label>{val.posation}</label>
              <h4>{val.name}</h4>

              <ul>
                {val.icon.map((icon, index) => (
                  <li key={index}>{icon}</li>
                ))}
              </ul>

            </div>
          </div>
        ))}
      </div>
    </div>
    <div className='container mtop'>
    <Heading  className=' mtop' title='Our ICT Support Team' subtitle='' />

    <div className='content mtop grid5'>
      {ictteam.map((val, index) => (
        <div className='box' key={index}>
         
          <div className='details'>
            <div className='img'>
              <img src={val.cover} alt='' />
             
            </div>
            
            <label>{val.posation}</label>
            <h5>{val.name}</h5>



          </div>
        </div>
      ))}
    </div>
  </div>
  <div className='container mtop'>
  <Heading  className=' mtop' title='Programs Depart' subtitle='' />

  <div className='content mtop grid5'>
    {programs.map((val, index) => (
      <div className='box' key={index}>
       
        <div className='details'>
          <div className='img'>
            <img src={val.cover} alt='' />
            
          </div>
          
          <label>{val.posation}</label>
          <h5>{val.name}</h5>



        </div>
      </div>
    ))}
  </div>
</div>
<div className='container mtop'>
<Heading  className=' mtop' title='RADIO PROGRAMMING TEAM' subtitle='' />

<div className='content mtop grid5'>
  {content.map((val, index) => (
    <div className='box' key={index}>
     
      <div className='details'>
        <div className='img'>
          <img src={val.cover} alt='' />
          
        </div>
        
        <label>{val.posation}</label>
        <h5>{val.name}</h5>



      </div>
    </div>
  ))}
</div>
</div>
<div className='container mtop'>
<Heading  className=' mtop' title='RADIO PROGRAMMING TEAM' subtitle='' />

<div className='content mtop grid5'>
  {radio.map((val, index) => (
    <div className='box' key={index}>
     
      <div className='details'>
        <div className='img'>
          <img src={val.cover} alt='' />
          <i className='fa-solid fa-circle-check'></i>
        </div>
        
        <label>{val.posation}</label>
        <h5>{val.name}</h5>



      </div>
    </div>
  ))}
</div>
</div>
<div className='container mtop'>
<Heading  className=' mtop' title='TRANSPORT & LOGISTICS TEAM' subtitle='' />

<div className='content mtop grid5'>
  {transport.map((val, index) => (
    <div className='box' key={index}>
     
      <div className='details'>
        <div className='img'>
          <img src={val.cover} alt='' />
          <i className='fa-solid fa-circle-check'></i>
        </div>
        
        <label>{val.posation}</label>
        <h5>{val.name}</h5>



      </div>
    </div>
  ))}
</div>
</div>
  </section>
    
    
    </>
  )
}

export default Staff