import React from "react"
import img from "../images/pricing.jpg"
import Back from "../common/Back"
import "./contact.css"
import googleMapReact from "google-map-react"
import Header from "../common/header/Header"

const AnyReactComponent = ({ text }) => <div>{text}</div> 


const Contact = () => {

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  return (
    <>
	<Header/>
    <section className="ftco-section">
		<div className="contactcontainer">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center mb-5">
					<h2 className="heading-section">Contact US</h2>
				</div>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-12">
					<div className="">
						<div className="row mb-5">
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
			        		<div className="icon d-flex align-items-center justify-content-center">
			        			<span className="fa fa-map-marker"></span>
			        		</div>
			        		<div className="text">
				            <p><span>Address:</span> Lilongwe Malawi, Area 47 Sector 4, Off Msokela Road, Mazengera Street, Plot # 862</p>
				          </div>
			          </div>
							</div>
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
			        		<div className="icon d-flex align-items-center justify-content-center">
			        			<span className="fa fa-phone"></span>
			        		</div>
			        		<div className="text">
				            <p><span>Phone:</span> <a href="tel://1234567920">+265 999 970 417</a></p>
				          </div>
			          </div>
							</div>
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
			        		<div className="icon d-flex align-items-center justify-content-center">
			        			<span className="fa fa-paper-plane"></span>
			        		</div>
			        		<div className="text">
				            <p><span>Email:</span> <a href="mailto:info@yoursite.com">info@farmradiomw.org</a></p>
				          </div>
			          </div>
							</div>
							<div className="col-md-3">
								<div className="dbox w-100 text-center">
			        		<div className="icon d-flex align-items-center justify-content-center">
			        			<span className="fa fa-globe"></span>
			        		</div>
			        		<div className="text">
				            <p><span>Website</span> <a href="#">farmradiomw.org</a></p>
				          </div>
			          </div>
							</div>
						</div>
						<div className="row no-gutters">
							<div className="col-md-7 padding">
								<div className="contact-wrap w-100 p-md-5 p-4">
									
									<div id="form-message-warning" className="mb-4"></div> 
				      		<div id="form-message-success" className="mb-4">
				            Your message was sent, thank you!
				      		</div>
									<form method="POST" id="contactForm" name="contactForm" className="contactForm">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label className="label" for="name">Full Name</label>
													<input type="text" className="form-control" name="name" id="name" placeholder="Name"/>
												</div>
											</div>
											<div className="col-md-6"> 
												<div className="form-group">
													<label className="label" for="email">Email Address</label>
													<input type="email" className="form-control" name="email" id="email" placeholder="Email"/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label className="label" for="subject">Subject</label>
													<input type="text" className="form-control" name="subject" id="subject" placeholder="Subject"/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label className="label" for="#">Message</label>
													<textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder="Message"></textarea>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<input type="submit" value="Send Message" className="btn btn-primary"/>
													<div className="submitting"></div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-md-5 d-flex align-items-stretch">
								<div className="info-wrap w-100 p-5 img backgroundco " >
			          </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
		

		  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3801.532594085601!2d33.76450903585448!3d-13.95157418766667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1921d343c338032f%3A0xcb4a205a3077c283!2sFarm%20Radio%20Trust%2C%20Malawi!5e1!3m2!1sen!2smw!4v1698071898680!5m2!1sen!2smw" 
		  width="100%"
		  height="500"
		  
		  style={{border:"0"}}
		  allowfullscreen="" 
		  loading="lazy" 
		  referrerpolicy="no-referrer-when-downgrade"></iframe>
		</div>
	</section>


    </>
  )
}

export default Contact
