
import React from 'react'

import "./about1.css"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation, EffectCreative  } from 'swiper/modules';

import Aos from "aos"
import Image from 'react-bootstrap/Image';
import iphone from "../../assets/images/iphonex.png"
import immio from "../../assets/images/immio.jpg"
import immio1 from "../../assets/images/immio1.jpg"
import immio2 from "../../assets/images/immio2.jpg"
import immio3 from "../../assets/images/immio3.jpg"
import immio4 from "../../assets/images/immio4.jpg"
import immio5 from "../../assets/images/immio5.jpg"
import immio6 from "../../assets/images/immio6.jpg"
import immio7 from "../../assets/images/immio33.jpg"
import immio8 from "../../assets/images/immio11.jpg"
import immio9 from "../../assets/images/immio44.jpg"
import immio10 from "../../assets/images/immio33.jpg"


Aos.init();

const Aboutcont = () => {

   
    
  


  return (
    <section className='about1 md:px-32'>
    <div className='container flex mtop'>
    <div   className='left row'>

      <div data-aos="fade-down" className='mb-8'>
      <h1 className=' text-3xl md:text-5xl '>Who are We?</h1>
      </div>      

      <p data-aos="fade-right">Farm Radio Trust "FRT" is a leading non- governmental organization dedicated exclusively to serving smallholder communities through the provision of ICT based information, communication, extension and advisory services in Malawi with a growing reputation and involvement in program and policy development processes across sub-Saharan Africa</p>
      <p data-aos="fade-up-right">Information plays a crucial role in the development of the world and People are often surprised to learn that radio is a powerful tool in international development and depend on it as their primary source of information. Thats why weve used radio and Other newer ICT technoligies in Sharing agriculture information which is accurate, reliable, trusted and of Value to farmers</p>
      <button className='px-4 py-2' >More About Us</button>
    </div>
    <div data-aos="fade-up-left" className='right'>
    <div className="piccontainer">
    <div className="wrapper">
    <Swiper
    spaceBetween={30}
    centeredSlides={true}
    speed={1300}
    autoplay={{
      delay: 4500,
      disableOnInteraction: false,
    }}
    grabCursor={true}
    effect={'creative'}
    creativeEffect={{
      prev: {
        shadow: false,
        translate: [0, 0, -400],
      },
      next: {
        translate: ['100%', 0, 0],
      },
    }}
   
    pagination={{
      clickable: true,
      
    }}
    navigation={false}
    modules={[Autoplay, Pagination, Navigation,EffectCreative]}
    className="mySwiper"
  >


    <SwiperSlide ><img className="mySlides" src={immio} alt=""/></SwiperSlide>
    <SwiperSlide > <img className="mySlides" src={immio1} alt=""/></SwiperSlide>
    <SwiperSlide >    <img className="mySlides" src={immio2} alt=""/></SwiperSlide>
    <SwiperSlide><img className="mySlides" src={immio3} alt=""/></SwiperSlide>
    
    <SwiperSlide>    <img className="mySlides" src={immio4} alt=""/></SwiperSlide>
    <SwiperSlide>    <img className="mySlides" src={immio5} alt=""/></SwiperSlide>
    <SwiperSlide>    <img className="mySlides" src={immio6} alt=""/></SwiperSlide>
    <SwiperSlide>    <img className="mySlides" src={immio7} alt=""/></SwiperSlide>
    <SwiperSlide>    <img className="mySlides" src={immio8} alt=""/></SwiperSlide>
    <SwiperSlide>    <img className="mySlides" src={immio9} alt=""/></SwiperSlide>
    <SwiperSlide>    <img className="mySlides" src={immio10} alt=""/></SwiperSlide>
  </Swiper>

    </div>
    </div>


    
      
    </div>
  </div>

     
    </section>
    
    
  )
}

export default Aboutcont
