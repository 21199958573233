import React from 'react'
import Gridw from './Gridw';

import "./whatwedo.css"
import { Icon } from '@iconify/react';


import Aos from 'aos'

Aos.init();

const Whatwedo = () => {
  return (
       <section  className="about">
        <div className="aboutcontainer flex" data-aos="fade-up">
  
         
            
            <div className="right row  contentbox" data-aos="fade-right" data-aos-delay="100">
              <div className='abouth1'><h1>ABOUT US</h1></div>
            
              <p className="fst-italic">        
                  Farm Radio Trust(MW) provides different services to farmers. These include
              </p>
              <ul>
                <li>Increased innovative rural development oriented extension and advisory services through radio programming and other ICTs</li>
                <li>Increased technical capacity of FRT and partner institutions to design, deliver and package quality and relevant rural development programming and content. </li>
                <li>Established research, knowledge management processes and systematic monitoring and evaluation for transformative programming.</li>
                <li>Increased advocacy and lobbying towards the role of ICT based extension and advisory services as a key information pathway for sustainable development.</li>
                <li>Effective networks and partnerships for ICT based extension and advisory services.</li>
                <li>Knowledge Management Services</li>

              </ul>
              <p>
                
              </p>
              <button className='btn2'>Know More</button>
            </div>
            <div className="left row  " data-aos="fade-left" data-aos-delay="100">
              <Gridw/>
            </div>
        
  
        </div>
      </section>
  )
}

export default Whatwedo
