import React from 'react'
import "../../news/design.css"

const Wedo = () => {
  return (
    <>
    <section className = "about" id = "about">
    <div className = "container">
      <div className = "about-content">
        <div>
          <img src = "./image/about-bg.jpg" alt = ""/>
        </div>
        <div className = "about-text">
          <div className = "title">
            <h1>WHAT WE DO</h1>
            <p>WE WORK FOR PERFECTION</p>
          </div>
          <ul>
          <li>Providing farmer advisory services through impactful radio programming in conjunction with other ICTs</li>
          <li>Training and building capacity of broadcasters and radio stations</li>  
          <li>Promoting participatory radio campaigns facilitated by the use of ICTs</li>
          <li>Researching, documenting and managing knowledge surrounding beneficial, evidence-based farming practices and farm radio programming</li>
          <li>Advocating for an amplified position of radio and ICTs in agricultural development, as well as for increased access to agricultural information by smallholder farmers.</li>
          <li>Supporting partnerships and collaboration within the farm radio world and associated industries</li>
        
          
          </ul>
          </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Wedo
