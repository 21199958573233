import React from "react"
import Heading from "../../common/Heading"
import "./recent.css"
import RecentCard from "./RecentCard"

const Recent = () => {
  return (
    <>
      <section className='recent padding md:px-32'>
        <div className='container'>
          <Heading title='Recent Posts' subtitle='Our Recently Posts, News, Activities and works we do at Farm Radio Trust ' />
          <RecentCard />
        </div>
      </section>
    </>
  )
}

export default Recent
