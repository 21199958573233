import React from 'react'
import "./hero.css"
import { Link } from "react-router-dom/cjs/react-router-dom"

const Messages = () => {
  return (
    <>
    <div className='w-full flex h-screen items-start heromessages'>

    <div className='relative w-full md:w-1/2 h-full flex flex-col mx-4 md:mx-12'>
    <div className='absolute top-{50%} left-{6%} flex flex-col my-8 textmargn'>
    
    <h1 className='text-4xl md:text-6xl text-white font-bold text-center md:pl-15'>SMS PLATFORM</h1>
    <p className='text-4xl text-white folt-normal text-center px-2 md:px-10 md:py-10'>

    FRT upscaled the SMS platform for dissemination of advisories and relevant information to famers. Approximately over 900,000 farmers received advisories and information during this year. The system also provided real-time delivery status updates, allowing administrators to monitor the progress of the push campaign and address any potential issues promptly.

    The SMS platform supported farmers with messages on various value chains  </p>
    <div className="divbutton flex">
     <Link to="/services">
         <button className=' mx-6'>
         <i class="fa fa-phone"></i>Call US Now
     </button>
     <button className='btn1 mx-6'>
         <i className='fa fa-envelope-open'></i>Read More
     </button>
     </Link>
     </div>
    </div> 
    
 </div>
 <div className='relative hidden md:block w-0 md:w-1/2 h-full '>
 
       
 </div>
  
</div>
    </>
  )
}

export default Messages
