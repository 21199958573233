import React from 'react'
import './projects.css'
import Back from "../../common/Back"
import img from "../../images/about.jpg"
import { Link } from "react-router-dom"
import { Card,CardContent,Typography,Button,Grid } from '@mui/material'
import Header from '../../common/header/Header'


import Recent from './recent/Recent'

const Projects = () => {
  return (
    <>
    <Header/>
    <Back cover={img} />


    <Recent/>
        
    


    
    </>
  )
}

export default Projects