import React from 'react'
import Header from '../../../common/header/Header'

function AgraYouth() {
  return (
    <>
    <Header/>
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
    <div className="absolute inset-0 -z-10 overflow-hidden">
      <svg
        className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
      </svg>
    </div>
    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div className="lg:pr-4">
          <div className="lg:max-w-lg">
            <p className="text-base font-semibold leading-7 text-green-600">Farm Radio Trust</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">TORs_for_FRT_Safeguarding_Policy</h1>
            <h3 className="mt-2 text-2xl  tracking-tight text-gray-900 sm:text-3xl">ORGANIZATION OVERVIEW</h3>
            
            
            
            <p className="mt-6 text-xl leading-8 text-gray-700">
            Farm Radio Trust (FRT) is a leading non- governmental organization dedicated exclusively to serving smallholder communities through the provision of ICT based information, communication, extension and advisory services in Malawi with a growing reputation and involvement in program and policy development processes across sub-Saharan Africa. Our vision is a ‘world that has sustainable livelihoods of farming communities’. FRT was registered under the Trustees Incorporation Act on 14 June 2009; registered as a local non-governmental organization under Council for Non-Governmental Organizations in Malawi (CONGOMA) on 17th November 2010 with registration number C510/2010. FRT is also registered with NGO Board since June 2013 with registration number NGO/R/13/58.
            </p>

            <h3 className="mt-2 text-2xl  tracking-tight text-gray-900 sm:text-3xl">LAKESHORE-YOUTH IN EMPLOYMENT AND AGRIBUSINESS DEVELOPMENT (LEAD) PROJECT
            Farm Radio Trust (FRT) is implementing</h3>
            
            
            
            <p className="mt-6 text-xl leading-8 text-gray-700">
            Farm Radio Trust (FRT) is implementing the Lakeshore-Youth in Employment and Agribusiness Development (LEAD) Project to increase agriculture productivity, resilience, incomes and economic opportunities for young Agripreneurs and farmers in Salima and Machinga Agriculture Development Divisions (ADDs).
            The LEAD project is part of the Youth Entrepreneurship for the Future of Food in Agriculture (YEFFA) programme, a 5-year programme being implemented by AGRA in collaboration with the Mastercard Foundation, whose goal is to catalyse an inclusive agri-food system transformation through a competitive agro-processing industry that drives farmer productivity, resilience, and dignified work opportunities particularly for youth and vulnerable people.
            FRT recognizes that there is potential for Abuse to take place within the context of its programs, which are intended to address the needs of vulnerable
            2
            communities and groups. Hence, seeking to engage the services of a consulting firm/ consultant to develop a safeguarding policy for to protect the Vulnerable Persons and the Youth that are engaged. </p>
                        
           
          </div>
          <div className="read-more my-5">
          <a href="TORs_for_FRT_Safeguarding_Policy.pdf" download="TORs_for_FRT_Safeguarding_Policy.pdf">
            <button type="button" class="text-black bg-gradient-to-br from-green-400 to-blue-600  focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-large rounded-lg px-4 py-2 text-center me-2 mb-2">
             Download the Annual Report
            </button></a>
          </div>
        </div>
      </div>
      <div className="-ml-16 -mt-8 -mr-28 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
        <img
          className="w-[111%]  rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          src="/image/blog/TORs_for_FRT_Safeguarding_Policy.png"
          alt=""
        />
       
      </div>

      <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div className="lg:pr-4">
          <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              {/** <p>
            Alimi kusadziwa ndikufa komwe, dziwani za mitengo yovomelezeka yogulitsira zokolola zanu. Mafumu, alangizi, mabwalo aza ulangizi ndi udido wathu kulimbikitsa mitengoyi m'madera mwanthu

            </p>
          
            <ul role="list" className="mt-8 space-y-8 text-gray-600">
              <li className="flex gap-x-3">
               - 
                <span>
                  <strong className="font-semibold text-gray-900">Push to deploy.</strong> Lorem ipsum, dolor sit amet
                  consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
                  blanditiis ratione.
                </span>
              </li>
              <li className="flex gap-x-3">
               - 
                <span>
                  <strong className="font-semibold text-gray-900">SSL certificates.</strong> Anim aute id magna aliqua
                  ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
                </span>
              </li>
              <li className="flex gap-x-3">
                - 
                <span>
                  <strong className="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien
                  vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.
                </span>
              </li>
            </ul>
            <p className="mt-8">
              Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor
              fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac
              adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">No server? No problem.</h2>
            <p className="mt-6">
              Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.
              Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed
              tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam
              turpis ipsum eu a sed convallis diam.
            </p>*/}
          </div>
        </div>
      </div>
    </div>
  </div>
      
    </>
  )
}

export default AgraYouth
