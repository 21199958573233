import React from 'react'
import Heading from "../../common/Heading"
import "./hero.css"
import { Link } from "react-router-dom/cjs/react-router-dom"
import {Typewriter} from 'react-simple-typewriter'
import { text } from '@fortawesome/fontawesome-svg-core'

const Welcome = () => {
  return (
    <>
    <section className='hero w-full h-screen '>
      <div data-aos="fade-up"  className='container'>

        <div className='heading'>
        <h2>Welcome to</h2>
        <h1>FARM RADIO TRUST</h1>
        <p style={{   fontWeight: 'normal' }}>
          We are{' '}
       <span style={{ color: 'white', fontWeight: 'bold' }}>
         {/* Style will be inherited from the parent element */}
         <Typewriter
           words={['Farmers First', 'Farmers ThroughOut', 'Farmers Last']}
           loop={200}
           cursor
           cursorStyle='_'
           typeSpeed={80}
           deleteSpeed={60}
           delaySpeed={1500}

         />
       </span>
     </p>
       </div>

       <div className="divbutton">
       <Link to="/services">
        <button className='btn1'>
        <i className='fa fa-money'></i> Get Started
      </button>
      </Link>
        </div>
        
      </div>
    </section>
  </>
  )
}

export default Welcome
