import React from "react"
import bg from "../../../assets/images/design.jpg"


const links = [
  { name: 'Open roles', href: '#' },
  { name: 'Internship program', href: '#' },
  { name: 'Our values', href: '#' },
  { name: 'Meet our leadership', href: '#' },
]
const stats = [
  { name: 'ICT Hubs',    icon: <i class="fa-solid fa-people-group"></i>, value: '1500' },
  { name: 'Districts of Engegement',icon: <i class="fa-solid fa-location-dot"></i>, value: '28' },
  { name: 'Hotline Monthly Cases',   icon: <i class='fa-solid fa-briefcase'></i>, value: '2,500' },
  { name: 'Radio Station Partners',    icon: <i class="fa-solid fa-radio"></i>, value: '35' },
]

const Awards = () => {
  return (
    <>
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
    <img
      src={bg}
      alt=""
      className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
    />
    <div
      className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      aria-hidden="true"
    >
      <div
        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div
      className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      aria-hidden="true"
    >
      <div
        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Work with us</h2>
        <p className="mt-6 text-lg leading-8 text-gray-300">
        Farm Radio Trust (FRT) is A knowledge broker and national leader in the provision of ICT
        based extension services across all key development sectors in Malawi. 

        </p>
      </div>
      <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">

        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat) => (
            <div key={stat.name} className="flex flex-col-reverse my-6">
             
              <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
              <dd className="text-3xl md:text-4xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
              <dd className= "text-5xl md:text-6xl font-bold leading-9 tracking-tight text-white">{stat.icon}</dd>
              
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
    </>
  )
}

export default Awards
