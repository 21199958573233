import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import "bootstrap/dist/css/bootstrap.min.css";
import "./price.css"
import iphone from "../../../assets/images/iphonex.png"



const Price = () => {
  return (
    <>
      <section className="Vision-section light-bg md:px-32">
      <Container>
      <Row>
      
      <Col md={8} className="colvalue">
      
      <ul className="list-unstyled ui-steps">
      <li className="media my-4">
      <div className="circle-icon mr-4"><i class="fa-solid fa-eye md:text-5xl"></i></div>
      <div className="media-body">
      <h3 className=" my-1 md:my-4" >VISION</h3>
      <p >To make a World that has sustainable livelihoods of farming communities </p>
       </div>
      </li>
      <li className="media  my-4">
      <div className="circle-icon mr-4"><i class="fa-solid fa-rocket md:text-5xl"></i></div>
      <div className="media-body">
      <h3 className="text-8xl my-1 md:my-4" >MISSION STATEMENT</h3>
      <p >To be a center of excellence for ICT based extension and advisory services through transformative programming, capacity building, advocacy and partnerships founded on research and knowledge management”.</p>
      </div>
      </li>

      <li className="media  my-4">
      <div className="circle-icon mr-4"><i class="fa-solid fa-flag-checkered md:text-5xl"></i></div>
      <div className="media-body">
      <h3 className=" my-1  md:my-4">OUR GOAL</h3>
      <p >Make sure that every rural family has access to reliable and advance-based information that they can use to improve their livelihoods and good health including the support they need to make positive change in their own communities    </p>
      </div>
      </li>
      </ul>

      
      </Col>
      <Col md={4}>
      <Image src={iphone} fluid />
      </Col>
      
      </Row>  
    
    </Container>
      </section>

    </>
  )
}

export default Price
