import React from 'react'
import { services } from '../../data/Data'
import { Link } from "react-router-dom"
const ServiceCard = () => {
  return (
    <>
    <div className='content grid3 mtop'>
      {services.map((items, index) => (
        <div className='box' key={index}>
        <Link to={items.link} ><img data-aos="zoom-in"src={items.cover} alt='' /></Link>

          <h5 zoom-out-right>{items.name}</h5>
          <label  data-aos="fade-up">{items.total}</label>
        </div>
      ))}
    </div>
  </>
  )
} 

export default ServiceCard