import React from "react"
import { footer } from "../../data/Data"
import {teamicon} from "../../data/Data"  
import "./footer.css"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <section className='footerContact'>
        <div className='container'>

        {teamicon.map((val,index) => (
          <div className="footicons" key={index}>
          <ul>
                    {val.icon.map((icon, index) => (
                      <li key={index}>{icon}</li>
                    ))}
           </ul>

          </div>


        ))}
          <div className='flex'>

            
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
            <div className='logo pb-3'>
            
            <Link to="/" ><img src='./images/logoa.png' alt='' /></Link>
           </div>
              <h4>Address:</h4>
              <p> Plot number 862 Area 47 Sector 4 Off Msokela Road,</p> <p>from Bwandilo, Mazengera Street</p>
              <h4>Phone:<span> <p>+265 993 449 245</p></span></h4>
              <h4>Email:<span> <p>info@farmradiomw.org</p></span></h4>

              <p className='btn1'>Contact Us Today</p>
            </div>
          </div>

          {/*footer.map((val) => (
            <div className='box'>
              <h3>{val.title}</h3>
              <ul>
                {val.text.map((items) => (
                  <li> {items.list} </li>
                ))}
              </ul>
            </div>
                ))*/}
        </div>
      </footer>
      <div className='legal'>
        <span>© 2024 Farm Radio Trust. Designd By FRT.</span>
      </div>
    </>
  )
}

export default Footer
