import React from 'react'
import Heading from '../../common/Heading'
import"./service.css"  
import ServiceCard from './ServiceCard'

const Service = () => {
  return (
    <>   
    <section className='service background'>
    <div className='container'>
      <Heading title='OUR SERVICES' />
      <ServiceCard/>
    </div>
    </section>
    </>
  )
}

export default Service