import React from 'react'
import Header from '../../common/header/Header'
import Back from '../../common/Back'
import img from "../../images/team.jpg"
import './trustee.css'

const Trustee = () => {
  return (
    <>
    <Header/>
    <Back  name='The Staff Members for FRT & FHL' title='FRT -The Staff' cover={img} />
    <section class="customer ">
    <div class="trusteecont">
      <h1>Board of Trustee</h1>
      <p>Even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to </p>

      <div class="owl-carousel owl-theme mtop content">
        <div class="item flex">
          <div class="image">
            <div class="img">
              <img src={img} alt=""/>
           
            </div>
          </div>
          <div class="text">
            <div class="heading flex1">
              <div class="para">
                <h2>MORO JINK</h2>
                <h3>CUSTOMER</h3>
              </div>
              <div class="para">
                <i class="fas fa-quote-left"></i>
              </div>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
        <div class="item flex">
          <div class="image">
            <div class="img">
              <img  src={img} alt=""/>
            </div>
          </div>
          <div class="text">
            <div class="heading flex1">
              <div class="para">
              <h2>MORO JINK</h2>
              <h3>CUSTOMER</h3>
              </div>
              <div class="para">
                <i class="fas fa-quote-left"></i>
              </div>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
        <div class="item flex">
          <div class="image">
            <div class="img">
              <img  src={img} alt=""/>
            </div>
          </div>
          <div class="text">
            <div class="heading flex1">
              <div class="para">
              <h2>MORO JINK</h2>
              <h3>CUSTOMER</h3>
              </div>
              <div class="para">
                <i class="fas fa-quote-left"></i>
              </div>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Trustee