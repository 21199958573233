import React from "react"
import { price } from "../../data/Data"

const PriceCard = () => {
  return (
    <>
      <div className='content flex mtop'>
        {price.map((item, index) => (
          <div className='box shadow' key={index}>
          <img src={item.cover} alt='' />
            <div className="boxcontent">
            <h3>{item.title}</h3>
            <p>{item.ptext}</p>

            {/*<ul>
              {item.list.map((val) => {
                const { icon, text, change } = val
                return (
                  <li>
                    <label
                      style={{
                        background: change === "color" ? "#dc35451f" : "#27ae601f",
                        color: change === "color" ? "#dc3848" : "#27ae60",
                      }}
                    >
                      {icon}
                    </label>
                    <p>{text}</p>
                    </li>
                )
              })}
            </ul>  */}
            <button
              className='btn5'
              style={{
                background: item.title === "What Is FRT" ? "#27ae60" : "#fff",
                color: item.title === "What Is FRT" ? "#fff" : "#27ae60",
              }}
            >
               {item.button}
            </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default PriceCard
