import React from 'react'

import { careTitle, careSubtitle, careList, referenceTitle, referenceSubtitle } from "../../../data/Data";
import imageFour from "../../../../assets/image-four.svg";
import imageOne from "../../../../assets/image-one.svg";
import imageTwo from "../../../../assets/image-two.svg";
import imageThree from "../../../../assets/image-three.svg";

import FadeIn from "../../../../components/FadeIn";
import Header from '../../../common/header/Header';
const ServicePage = () => {
  return (
    <>
    <Header/>
    <section className='pt-32'>
    <div className='max-h-12'></div>
    <div id="reference" className="mb-[160px] px-10 max-w-[1490px] mx-auto ">
    <FadeIn delay={0.2} direction="down">
      <h1 className="text-4xl lg:text-[64px] font-medium text-fontBlack mb-6 text-center">
        {referenceTitle}
      </h1>
    </FadeIn>
    <FadeIn delay={0.4} direction="down">
      <h5 className="text-[#4F4F4F] text-lg xs:text-xl mb-12 text-center">
        {referenceSubtitle}
      </h5>
    </FadeIn>

    <div className="flex flex-col md:flex-row md:justify-center gap-8">
      <FadeIn delay={0.2} direction="right">
        <div className="flex flex-col gap-8">
          <img src={imageOne} alt="" />
          <img src={imageTwo} alt="" />
        </div>
      </FadeIn>
      <FadeIn delay={0.2} direction="left">
        <div>
          <img src={imageThree} alt="" />
        </div>
      </FadeIn>
    </div>
  </div>

  <div id="care" className="px-10 max-w-[1490px] mx-auto mb-[160px]">
  <FadeIn delay={0.2} direction="down">
    <h1 className="text-5xl lg:text-[64px] font-medium text-fontBlack mb-6 text-center">
      {careTitle}
    </h1>
  </FadeIn>

  <FadeIn delay={0.4} direction="down">
    <h5 className="text-[#4F4F4F] text-lg xs:text-xl mb-12">
      {careSubtitle}
    </h5>
  </FadeIn>

  <div className="flex flex-col lg:flex-row gap-8">
    <div className="flex flex-col gap-8 items-start">
      {careList.map((item, i) => (
        <FadeIn key={i} delay={(i + 1) * 0.2} direction="left">
          <div className="flex flex-col xs:flex-row gap-6 items-center xs:items-start">
            <img
              src={item.img}
              alt={item.title}
              className="h-[88px] w-[68px]"
            />
            <div>
              <h3 className="text-center xs:text-start mb-2 text-2xl lg:text-[28px] text-fontBlack font-medium">
                {item.title}
              </h3>
              <h6 className="text-center xs:text-start text-base lg:text-lg text-fontGray font-medium">
                {item.subtitle}
              </h6>
            </div>
          </div>
        </FadeIn>
      ))}
    </div>

    <FadeIn delay={0.6} direction="right">
      <img src={imageFour} alt="plants" />
    </FadeIn>
  </div>
</div>

</section>
    </>
  )
}

export default ServicePage
