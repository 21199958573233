import React from "react"

import "./projects.css"
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom"

import ProjectBack from "../common/ProjectBack"
import img from "../images/sriied.jpg"
import img1 from "../../assets/images/srieedProject.jpg"
import img2 from "../../assets/images/mlimiradio.jpg"
import Header from "../common/header/Header"


const Srieed = () => {
  const sectionStyle = {
    borderTop: 'solid #1bbd36 !important', // Correct syntax for border-top
  };

  return (
    <>
    <Header/>
      <section data-aos="fade-up" style={sectionStyle} className=' border' >
     
      <ProjectBack cover={img} />
        
      </section>
      <section id="blog" className="blog">
      
      <div className="container" data-aos="fade-up">
  
        <div className="row">
  
          <div className="col-lg-8 entries">
          
          <article className="entry">
  
          <div className="entry-img">
            <img src="./image/blog/rex.jpg" alt="" className="img-fluid"/>
          </div>
  
          <h1 className="entrytitle">
            <a >The Context</a>
          </h1>
          <div className="entrycontent">
            <h2>
            In order to contribute towards improved food security, nutrition, income and resilience by smallholder farmers, FRT in collaboration with the Ministry of Agriculture through the Department of Agricultural Extension Services (DAES), is implementing the Scaling up Radio and ICTs in Enhancing Extension Delivery (SRIEED) II Project. </h2>
            
          </div>

          <div className="">
            <img src={img1} alt="" className="img-fluid"/>
          </div>


          <div className="entry-content Aims">
          <h1 className="entrytitle">
          <a >The Project Goals</a>
          </h1>

          <div className="row bg-clip-padding">
          <div className="col-lg-6 lineheight ">
          <p>The project aims at: </p>
          <ul>
            <li>Increasing awareness, knowledge and skills of adult and youth male and female SHFs in agriculture extension 
            through provision of comprehensive information on Agriculture extension and advisory services.</li>
            <li>Increasing application and adoption of disseminated technologies among men, women, male and female youths</li>
            <li>Establishing an enabling and conducive policy environment for ICT enhanced extension and advisory services; as well as achieving a higher use of market linkages platforms by adult and youth
            male and female SHFs</li>
            <li>establishing an enabling and conducive policy environment
            for ICT enhanced extension and advisory services; </li>
            <li>as well as achieving a higher use of market linkages platforms by adult and youth
            male and female SHFs</li>
          </ul>


          
           <h2> The project targets smallholder farmers households 
           in Mzimba, Kasungu, Nkhotakota, Lilongwe and Mangochi districts.</h2>
          
          <h2>Through the ICT hubs, the project continued to support farmers in the use of digital platforms to access extension and advisory to enhance technology adoption.</h2>
          <h2>The project further upscaled youth involvement in digital extension through digital champions in Kasungu.</h2>
           </div>
          <div className="col-lg-6 secondpara">
          <h2>
          <div className="">
          <img src={img2} alt="" className="img-fluid"/>
        </div>
          One of the key highlights in the year 2022/23, through the project, was the official launch of the first ever pro-agricultural radio station, Mlimi Radio on 25th March 2023  
         
          
          </h2>
          <h2>Furthermore, the project continued disseminating agricultural extension and advisories as follows: </h2>
          <ul>
          <li>
          Tipindule ndi Ulimi Radio program through the following radio stations: Kasungu, Voice of Livingstonia, Mlimi Radio and ZBS; 
          </li>
          <li>Supporting various farmer queries through the Mlimi Hotline Call centre;   </li>
          <li>Provision of extension 24/7 via Interactive Voice Response platform</li>
          <li>Provision of advisory and weather alerts through SMS Platform</li>
          <li>provision of advisory services, market and resilience information through the Mlimi App</li>
          <li>Sharing agricultural technologies with farmers using video extension</li>
          <li>Maintained the provision of agriculture information/extension for use by various agricultural extension actors through the Malawi Agriculture Content Portal (MACoP). </li>
          </ul>

          
          </div>
          
          </div>
          


          
          
        </div>
  
          
  
          <div className="entry-meta">
            <ul>
              <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
              <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">1st August 2020 to 31st July 2025</time></a></li>
              <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html"></a></li>
            </ul>
          </div>
  

        </article>

  
      
          </div>
  
          <div className="col-lg-4">
  
            <div className="sidebar">
  

              <div className="sidebar-item1 categories">
              <h3 className="sidebar-title1">Partners</h3>
              <ul>
                <li>Government of Flanders</li>


              </ul>
            </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
  
             
              <div className="sidebar-item1 tags">
              <h3 className="sidebar-title1">LOCATION </h3>
                <ul>
                  <li><a href="#">Mzimba</a></li>
                  <li><a href="#">Kasungu</a></li>
                  <li><a href="#">Nkhotakota</a></li>
                  <li><a href="#">Lilongwe </a></li>
                  <li><a href="#">Mangochi </a></li>

                </ul>
              </div>
  
            </div>
  
          </div>
  
        </div>
  
      </div>
    </section>
    </>
  )
}

export default Srieed
