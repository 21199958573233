import React from "react"
import { allProjects } from "../../../data/Data"

import { Link } from "react-router-dom"


const RecentCard = () => {
  return (
    <>
      <div  className='content grid3 mtop'>
        {allProjects.map((val, index) => {
          const { cover, category, period, name, price, type,ptext,url } = val
          return (
            <div className='box shadow' key={index}>
            <Link to={url} >
              <div className='img'>
                <img src={cover} alt='' />
              </div>
              <div className='text'>

                <h4>{name}</h4>
                <p>
                  <i className='fa '></i> {ptext}
                </p>
                <p>
                  <i className='fa fa-clock'></i> {period}
                  
                </p>
              </div>
              </Link>

            </div>
          )
        })}
      </div>
    </>
  )
}

export default RecentCard
