import React from "react"
import '../about/about.css'
import Aos from "aos"
import Awards from "./awards/Awards"
import Featured from "./featured/Featured"
import Hero from "./hero/Hero"
import Locationicon from "./location/Locationicon"
import Header from "../common/header/Header"

import Aboutcont from "../about/Aboutcont"
import Location from "./location/Location"
import Whatwedo from "../about/Whatwedo"
import Price from "./price/Price"
import Partners from "./location/Partners"
import Recent from "./recent/Recent"
import Team from "./team/Team"
Aos.init({
  delay: 2, // values from 0 to 3000, with step 50ms
  duration: 1000,
});

const Home = () => {
  return (
    <>
      
      <Header/>
      <Hero/>
      <Aboutcont/>
      <Featured/>
      <Price/>
      <Awards/>
      <Recent/>
      <Team/>
      <Location/>     
    
     
      
      
         
      



      
    </>
  )
}

export default Home
