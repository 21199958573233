import React from 'react'
import "../design.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Latest = () => {
  return (
    <>
    <section className = "design" id = "design">
    <div className = "container">
      <div className = "title">
        <h2>Recent Arts & Designs</h2>
        <p>recent arts & designs on the blog</p>
      </div>

      <div className = "design-content">
     
        <div className = "design-item">
          <div className = "design-img">
            <img src = "./image/art-design-1.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i> 22</span>
            <span>Conference</span>
          </div>
          <div className = "design-title">
            <Link to="/publications" >Malawi Agricultural Productivity and Commercialisation Conference</Link>
          </div>
        </div>

        <div className = "design-item">
          <div className = "design-img">
            <img src = "./image/art-design-2.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i> 22</span>
            <span>Agriculture Fair</span>
          </div>
          <div className = "design-title">
            <a href = "#">19th National Agriculture Fair </a>
          </div>
        </div>

        <div className = "design-item">
          <div className = "design-img">
            <img src = "./image/art-design-3.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i> 22</span>
            <span>FRT and NPC</span>
          </div>
          <div className = "design-title">
            <a href = "#">FRT and NPC have signed a Memorandum of Understanding (MoU)</a>
          </div>
        </div>

        <div className = "design-item">
          <div className = "design-img">
            <img src = "./image/art-design-4.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i> 22</span>
            <span>Mlimi Radio</span>
          </div>
          <div className = "design-title">
            <a href = "#"> Finally, Mlimi Radio, M'godi wa Uthenga, has officially been launched today.</a>
          </div>
        </div>

        <div className = "design-item">
          <div className = "design-img">
            <img src = "./image/art-design-5.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i> 22</span>
            <span>Farm Radio Trust</span>
          </div>
          <div className = "design-title">
            <a href = "#">leading ICT based non-profit making organization in Malawi</a>
          </div>
        </div>

        <div className = "design-item">
          <div className = "design-img">
            <img src = "./image/art-design-6.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i> 22</span>
            <span>FRT</span>
          </div>
          <div className = "design-title">
            <a href = "#">Distribution of Radios to farmer's ICT hubs</a>
          </div>
        </div>

      </div>
    </div>
  </section>
    </>
  )
}

export default Latest
