import React from 'react'
import img from "../images/services.jpg"
import Back from "../common/Back"
import "../home/featured/Featured.css"
import NewsCard from './NewsCard'
import LatestNew from './LatestNews/LatestNew'
import Latest from './latest/Latest'
import Header from '../common/header/Header'

const News = () => {
  return (
    <>
    <Header/>
    <section className='services mb'>
    <Back name='News & Media' title='News -All News' cover={img} />
    <LatestNew/>
    <div className='container'>
    <Latest/>
    </div>

      
  </section>
  </>
  )
}

export default News
