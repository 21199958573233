import React from 'react'
import "./mission.css"
import Back from '../common/Back'

import img from "../images/team.jpg"
import Header from '../common/header/Header' 

const Mission = () => {
  return (
    <>
    
        <Header/>
        <section className='mission'>
        <div className='missio'>
        <h1>Mission & Vision</h1>
        </div>
        
        
        <div className="container">
        
        <div className="timeline">
        
       <ul>
         <li>
           <div className="timeline-content">
            
             <h1>OUR MISSION</h1>
             <p>To be a center of excellence for ICT based extension and advisory services through transformative programming, capacity building, advocacy and partnerships founded on research and knowledge management”.</p>
           </div>
         </li>
         <li>
           <div className="timeline-content">
             
             <h1>OUR VISION</h1>
             <p> Our vision is to Have a'World that has sustainable livelihoods of farming communities’ </p>
           </div>
         </li>
         <li>
           <div className="timeline-content">
             
             <h1>ABOUT US</h1>
             <p>Farm Radio Trust (FRT) is a leading non- governmental organization dedicated exclusively to serving smallholder communities through the provision of ICT based information, communication, extension and advisory services in Malawi with a growing reputation and involvement in program and policy development processes across sub-Saharan Africa</p>
           </div>
         </li>
         <li>
           <div className="timeline-content">
           
             <h1>OUR MOTTO</h1>
             <p>Farmers First , Farmers Throughout, Farmers Last</p>
           </div>
         </li>
       </ul>
     </div>
   </div>
        
        
        
        </section>
    
    </>
  )
}

export default Mission