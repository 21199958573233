import React from 'react'
import "./historytl.css"
import Header from '../../common/header/Header'

const Historytl = () => {
  return (
    <>
         <Header/>       
         <section className='historytl'>
            <div className='histo'>
            <h1>OUR HISTORY TIMELINE</h1>
            </div>
            
            
            <div className="container">
            
         <div className="timeline">
            
        <ul>
            <li>
            <div className="timeline-content">
                 <h3 class="date">dayth month, 2007</h3>
                <h1>STARTING STORY </h1>
                <p> For years, many have suspected radio’s potential as an informative tool to help smallholder farmers in Africa better obtain food, nutrition and income security.  But as of 2007, little data had been collected to prove these suspicions true. </p>
            </div>
            </li>
            <li>
            <div className="timeline-content">
                <h3 class="date">2007 - 2009</h3>
                <h1>RESEARCH STORY</h1>
                <p>  Farm Radio International (FRI), a Canadian NGO, launched a 3-year study that was conducted in 5 African countries, including Malawi. The research findings confirmed radio’s status as an accessible, powerful medium of communication in the promotion of improved agricultural practices. </p>
            </div>
            </li>
            <li>
            <div className="timeline-content">
                <h3 class="date"> may, 2009</h3>
                <h1>RESEARCH RESULTS</h1>
                <p>The research findings confirmed radio’s status as an accessible, powerful medium of communication in the promotion of improved agricultural practices. The project found radio to be particularly valuable when combined with ICTs to develop participatory radio programming, which gives listeners a voice and allows them to get involved</p>
            </div>
            </li>
            <li>
            <div className="timeline-content">
            <h3 class="date">20th may, 2010</h3>
                <h1>FRT BORN</h1>
                <p>Born from this research project was FRT, an independent, self-existent organization, established in 2009.  Since our inception, FRT has made strides in agricultural and rural development through our variety of programs.</p>
            </div>
            </li>
            <li>
            <div className="timeline-content">
            <h3 class="date">2009</h3>
                <h1>FRT REGISTRATION</h1>
                <p>Accreditations & Partnerships FRT was officially registered as a trust in July 2009. It is also registered as an NGO with the Council for Non-Governmental Organizations’ in Malawi (CONGOMA), and as well as with the NGO Board of Malawi. Today, FRT maintains a strategic partnership with FRI.</p>
            </div>
            </li>
            <li>
            <div className="timeline-content">
            <h3 class="date">2009-</h3>
                <h1>ACTIVITIES WE DO</h1>
                <p>Providing farmer advisory services </p>
                <p>Training and building capacity</p>
                <p>Promoting participatory radio campaigns</p>
                <p>Researching, documenting and managing knowledge</p>
                <p>Advocating for an amplified position of radio and ICTs in agricultural developmen</p>
                <p>Supporting partnerships and collaboration</p>

            </div>
            </li>

        </ul>
        </div>
        </div>
    
    
    
    </section>
    
    
    </>
  )
}

export default Historytl