import React from "react"
import Back from "../common/Back"
import Hstory from "../aboutus/Hstory"
import Newsdesign from "../news/Newsdesign"
import img from "../images/about.jpg"
import Aboutcont from "./Aboutcont"
import Header from "../common/header/Header"

const About = () => {
  const sectionStyle = {
    borderTop: 'solid #1bbd36 !important', // Correct syntax for border-top
  };

  return (
    <>
    <Header/>
      <section data-aos="fade-up" style={sectionStyle} className='about border' >
        <Back name='About Farm Radio Trust' title='About FRT - Who We Are?' cover={img} />
        <Aboutcont/>
        <Hstory/>
        <Newsdesign/>
        
      </section>
    </>
  )
}

export default About
