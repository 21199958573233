import React from 'react'
import CALLCENTRE from "../../../assets/image/callcentre.jpg"
import "./hero.css"
import { Link } from "react-router-dom/cjs/react-router-dom"

const Mobile = () => {
  return (
    <>
    <div className='w-full flex h-screen items-start heromobile'>
    <div className='relative  w-8/12 sm:w-9/12 md:w-1/2 h-full flex flex-col mx-4 md:mx-8'>
       <div className='absolute top-{50%} left-{6%} flex flex-col my-8 textmargn'>
       
       <h1 className='text-4xl md:text-6xl text-yellow-100 font-bold md:text-center   md:pl-15'>MLIMI APP</h1>
       <p className='text-4xl text-white folt-normal md:text-center md:px-10  md:py-14'>
        Mlimi App, a mobile app, is an innovative and user-friendly platform developed by Farm Radio Trust to offer real time advisories on crops, livestock, financial literacy, financial services, marketing and weather tailor-made for each district. Mlimi app also provides instant access to extension services through its integration with the Mlimi Hotline

       </p>
       <div className="divbutton flex">
        <Link to="/services">
            <button className='p-2  bg-white '>
          <i className="fa-solid fa-download"></i>Download Now
        </button>
        <button className='p-2  bg-white btn1 mx-6'>
            <i className='fa fa-envelope-open'></i>Read More
        </button>
        </Link>
        </div>
       </div> 
       
    </div>
  
</div>
    </>
  )
}

export default Mobile
