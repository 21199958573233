import React from "react"
import Heading from "../../common/Heading"
import { location } from "../../data/Data"
import "./style.css"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import {Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';


const Location = () => {
  return (
    <>
      <section className='location padding'>
        <div className='container'>
          <Heading title='Our Partners' subtitle='Below is the List of some of our Partners we work with ' />

          <div className=''>
          <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          autoScrollOffset={1}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 12000,
            disableOnInteraction: false,
          }}
          
          pagination={{
            clickable: true,
            
          }}
          
          modules={[Autoplay, EffectCoverflow, Pagination]}
          className="mySwiper"
        >
        {location.map((item, index) => (
          <SwiperSlide  key={index}>
            <img  src={item.cover} />
          </SwiperSlide>

        ))}
        
        </Swiper>

            
          </div>
        </div>
      </section>
    </>
  )
}

export default Location
