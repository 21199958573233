import React from 'react'
import "../design.css"

const LatestNew = () => {
  return (
    <>
    <section className = "blog" id = "blog">
    <div className = "container">
      <div className = "title">
        <h2>Latest News & Events</h2>
        <p>recent News about FRT & FHL</p>
      </div>
      <div className = "blog-content">

        <div className = "blog-item">
          <div className = "blog-img">
            <img src = "./image/blog-p-1.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i></span>
          </div>
          <div className = "blog-text">
            <span>20 January, 2023</span>
            <h2>Lorem ipsum, dolor sit amet consectetur adipisicing</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis libero quas ipsum laudantium nihil! Quaerat.</p>
            
            <button>Read More</button>
          </div>
        </div>

        <div className = "blog-item">
          <div className = "blog-img">
            <img src = "./image/blog-p-2.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i></span>
          </div>
          <div className = "blog-text">
            <span>20 January, 2023</span>
            <h2>Lorem ipsum, dolor sit amet consectetur adipisicing</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis libero quas ipsum laudantium nihil! Quaerat.</p>
            <button>Read More</button>
          </div>
        </div>

        <div className = "blog-item">
          <div className = "blog-img">
            <img src = "./image/blog-p-3.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i></span>
          </div>
          <div className = "blog-text">
            <span>20 January, 2023</span>
            <h2>Lorem ipsum, dolor sit amet consectetur adipisicing</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis libero quas ipsum laudantium nihil! Quaerat.</p>
            <button>Read More</button>
          </div>
        </div>

        <div className = "blog-item">
          <div className = "blog-img">
            <img src = "./image/blog-p-4.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i></span>
          </div>
          <div className = "blog-text">
            <span>20 January, 2023</span>
            <h2>Lorem ipsum, dolor sit amet consectetur adipisicing</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis libero quas ipsum laudantium nihil! Quaerat.</p>
            <button>Read More</button>
          </div>
        </div>

        <div className = "blog-item">
          <div className = "blog-img">
            <img src = "./image/blog-p-5.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i></span>
          </div>
          <div className = "blog-text">
            <span>20 January, 2023</span>
            <h2>Lorem ipsum, dolor sit amet consectetur adipisicing</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis libero quas ipsum laudantium nihil! Quaerat.</p>
            <button>Read More</button>
          </div>
        </div>

        <div className = "blog-item">
          <div className = "blog-img">
            <img src = "./image/blog-p-6.jpg" alt = ""/>
            <span><i className = "far fa-heart"></i></span>
          </div>
          <div className = "blog-text">
            <span>20 January, 2023</span>
            <h2>Lorem ipsum, dolor sit amet consectetur adipisicing</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis libero quas ipsum laudantium nihil! Quaerat.</p>
            <button>Read More</button>
          </div>
        </div>

      </div>
    </div>
  </section>
    </>
  )
}

export default LatestNew
