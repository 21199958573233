import React from 'react'
import "./executive.css"
import img from "../../images/team.jpg"
import Back from '../../common/Back'
import ExecutiveCard from './ExecutiveCard'
import Header from '../../common/header/Header'

const Executive = () => {
  return (
    <>
    <Header/>
    <Back name='The Executive Team for FRT' title='FRT - Exectutive' cover={img} />
    <ExecutiveCard/>
    
    
    
    
    </>
  )
}

export default Executive