import "./App.css"
import Pages from "./components/pages/Pages"
//import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.



function App() {

  return (
    <>
      <Pages/>
    </>
  )
}

export default App