import React, { useRef, useState } from 'react';
import Heading from "../../common/Heading"

import { ImageList, ImageListItem, ImageListItemBar, ListSubheader, IconButton  } from '@mui/material';
import { Info } from '@mui/icons-material';

import "./Featured.css"

import 'bootstrap/dist/css/bootstrap.css';



// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Welcome from '../hero/Welcome';
import Mobile from '../hero/Mobile';
import CallCentre from '../hero/CallCentre';
import Radio from '../hero/Radio';  
import img1 from '../../../assets/images/Accountability.jpg'
import img2 from '../../../assets/images/Farmer Centeredness.jpg'
import img3 from '../../../assets/images/Innovativeness.jpg'
import img4 from '../../../assets/images/MultiMedia Approach.jpg'
import img5 from '../../../assets/images/Productiveness.jpg'


const itemData = [
  {
    img: img1,
    title: 'Accountability',
    author: '@FarmRadioTrust',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: img2,
    title: 'Farmer Centeredness',
    author: '@FarmRadioTrust',
  },
  {
    img: img3,
    title: 'Innovativeness',
    author: '@FarmRadioTrust',
    cols: 2,
  },
  {
    img: img4,
    title: 'MultiMedia Approach',
    author: '@FarmRadioTrust',
    cols: 2,
  },
  {
    img: img5,
    title: 'Productiveness',
    author: '@FarmRadioTrust',
    rows: 2,
    cols: 2,
    featured: true,
  }
];


const Featured = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
      <section className='featured background'>
        <div className='container'>
          <Heading title='OUR VALUES' subtitle=' Our priorities in how we work ' />
          <section className='mt-20'>

        
          <Swiper
           slidesPerView={4}
            spaceBetween={30}
           
            centeredSlides={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft} 
            className="mySwiper"
          >
          {itemData.map((item) => (
            <SwiperSlide key={item.img}> 
           
            
              <ImageListItem >
                <img
                  srcSet={`${item.img}`}
                  src={`${item.img}`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.author}

                />
              </ImageListItem>
          
          
        
          </SwiperSlide>
          ))}
         
            <div className="autoplay-progress mt-11" slot="container-end">
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <span ref={progressContent}></span>
            </div>
          </Swiper>
      
           
          </section>
        </div>
      </section>
    </>
  )
}

export default Featured
