import React from 'react'
import "./design.css"
const Newsdesign = () => {
  return (
    <section className = "design" id = "design">
      <div className = "container">
        <div className = "title">
          <h2>Recent Events</h2>
          <p>our Recently Events at Farm Radio Trust</p>
        </div>

        <div className = "design-content">

          <div className = "design-item">
            <div className = "design-img">
              <img src = "./image/art-design-1.jpg" alt = ""/>
              <span><i className = "far fa-heart"></i> 23 July,2023</span>
              <span>Malawi 2063</span>
            </div>
            <div className = "design-title">
              <a href = "#">FRT and NPC have signed a Memorandum of Understanding (MoU)</a>
            </div>
          </div>

          <div className = "design-item">
            <div className = "design-img">
              <img src = "./image/art-design-2.jpg" alt = ""/>
              <span><i className = "far fa-heart"></i>25 May,2023</span>
              <span> Climate Services</span>
            </div>
            <div className = "design-title">
              <a href = "#">Training of District Agriculture Communications Officers and Climate Services to Officers</a>
            </div>
          </div>

          <div className = "design-item">
            <div className = "design-img">
              <img src = "./image/art-design-3.jpg" alt = ""/>
              <span><i className = "far fa-heart"></i>25 May,2023 </span>
              <span>Outreach Bundle</span>
            </div>
            <div className = "design-title">
              <a href = "#">communication and outreach Bundles</a>
            </div>
          </div>

          <div className = "design-item">
            <div className = "design-img">
              <img src = "./image/art-design-4.jpg" alt = ""/>
              <span><i className = "far fa-heart"></i> 25 May,2023</span>
              <span>Happy Africa Day</span>
            </div>
            <div className = "design-title">
              <a href = "#">Today, we celebrate the vibrant cultures our beautiful continent. </a>
            </div>
          </div>
         
          <div className = "design-item">
            <div className = "design-img">
              <img src = "./image/art-design-5.jpg" alt = ""/>
              <span><i className = "far fa-heart"></i>25 March,2023</span>
              <span>Mlimi Radio launch</span>
            </div>
            <div className = "design-title">
              <a href = "#">Finally, Mlimi Radio, M'godi wa Uthenga, has officially been launched today..</a>
            </div>
          </div>

          <div className = "design-item">
            <div className = "design-img">
              <img src = "./image/art-design-6.jpg" alt = ""/>
              <span><i className = "far fa-heart"></i> 355</span>
              <span>SRIID 2</span>
            </div>
            <div className = "design-title">
              <a href = "#"></a>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Newsdesign
