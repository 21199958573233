import React from 'react'
import CALLCENTRE from "../../../assets/image/callcentre.jpg"
import "./hero.css"
import { Link } from "react-router-dom/cjs/react-router-dom"

const Radio = () => {
  return (
    <>
    <div className='w-full flex h-screen items-start heroRadio'>
    <div className='relative  w-8/12 sm:w-9/12 md:w-1/2 h-full flex flex-col mx-4 md:mx-8'>
       <div className='absolute top-{50%} left-{6%} flex flex-col my-8 textmargn'>
       
    
       
       </div> 
       
    </div>
  
</div>
    </>
  )
}

export default Radio
