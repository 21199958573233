import React from 'react'
import Heading from '../common/Heading'
import "./history.css"

const Hstory = () => {
  return (
    <>
      <section className='history '>
        <div className='historycontainer'>
          <Heading title='OUR  STORY' />

          <div className='historycontent '>

            <div className='right htext'>
            <h3>How We started</h3>
            <p>

            Our Story For years, many have suspected radio’s potential as an informative tool
             to help smallholder farmers in Africa better obtain food, nutrition and income security. 
              But as of 2007, little data had been collected to prove these suspicions true. Farm Radio International (FRI), 
              a Canadian NGO, launched a 3-year study that was conducted in 5 African countries, including Malawi. The research 
              findings confirmed radio’s status as an accessible, powerful medium of communication in the promotion of improved agricultural practices. 
              The project found radio to be particularly valuable when combined with ICTs to develop participatory radio programming, which gives listeners 
              a voice and allows them to get involved. Born from this research project was FRT, an independent, self-existent organization,
               established in 2009.  Since our inception, FRT has made strides in agricultural and rural development through our variety of programs.
            </p><p>
             Farm Radio was registered and approved to start
            taking office on 14th June 2009; and it was approved by the branch
            in charge of all non-governmental organizations, the Council for Non-Governmental
            Organizations in Malawi (CONGOMA) on 17th November 2010.
             
            
            The organization also plays a major role in ensuring that policy
            agricultural regulations are being properly planned and enforced, and
            the aim is for farmers to have a good life and stay at home
            them.
            </p>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Hstory
