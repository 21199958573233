import React from 'react'
import "./publications.css"
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom"
import Header from '../../common/header/Header'


const Publications = () => {
  return (
    <>
    <Header/>
    <section id="blog" className="blog">
    <div className="container " data-aos="fade-up">

      <div className="row">

        <div className="col-lg-8 entries">
        <article className="entry">

        <div className="entry-img">
          <img src="./image/blog/TORs_for_FRT_Safeguarding_Policy.png" alt="" className="img-fluid"/>
        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">DEVELOPMENT OF SAFEGUARDING POLICY FOR VULNERABLE PERSONS AND YOUTH</a>
        </h2>

        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">OCT 02, 2024</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">2 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>
          Farm Radio Trust (FRT) is a leading non- governmental organization dedicated exclusively to serving smallholder communities through the provision of ICT based information, communication, extension and advisory services in Malawi with a growing reputation and involvement in program and policy development processes across sub-Saharan Africa.
          </p>
          <div className="read-more my-5">
          <Link to='/Publications/agra' >Read More</Link>
          <a className='  ml-3' href="TORs_for_FRT_Safeguarding_Policy.pdf" download="TORs_for_FRT_Safeguarding_Policy.pdf">Download </a>
          </div>
        </div>

      </article>+
        <article className="entry">

        <div className="entry-img">
          <img src="./image/blog/2022_2023_Annual_Report_Cover.png" alt="" className="img-fluid"/>
        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">FRT npm 2022-2023 Annual Report</a>
        </h2>

        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">April 25, 2024</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">2 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>
          The 2022-2023 Farm Radio Trust Annual Report outlines some of the key successes and transformative stories captured in the years 2022-2023 that were driven by Malawi’s 2063 development priority areas.
          </p>
          <div className="read-more my-5">
          <Link to='/news/AnnualReport' >Read More</Link>
          <a className='  ml-3' href="2022_2023_Annual_ Report.pdf" download="2022_2023_Annual_ Report.pdf">Download </a>
          </div>
        </div>

      </article>
        
        <article className="entry">

        <div className="entry-img">
          <img src="./image/blog/p1.jpg" alt="" className="img-fluid"/>
        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">Unpaid Care work in Malawi</a>
        </h2>

        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">April 25, 2024</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">2 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>
          Farm Radio Trust in collaboration with Farm Radio International, Ministry of Gender, Community Development and Social Welfare,  UN Women conducted an On Air Dialogue on the Status of Unpaid Care work in Malawi On the 30th of April 2024.
          <br/>Click download to download the panel presentation pdf</p>
          <div className="read-more ">
        
          <a className='mx-5' href="Ucare.pdf" download="ucare.pdf">Download </a>
          </div>
        </div>

      </article>

        <article className="entry">

        <div className="entry-img">
          <img src="./image/blog/farmgateprices.png" alt="" className="img-fluid"/>
        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">Farm Gate Prices</a>
        </h2>

        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">April 25, 2024</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">2 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>
          Alimi kusadziwa ndikufa komwe, dziwani za mitengo yovomelezeka yogulitsira zokolola zanu. Mafumu, alangizi, mabwalo aza ulangizi ndi udido wathu kulimbikitsa mitengoyi m'madera mwanthu
          </p>
          <div className="read-more">
          <Link to='/news/farmgate' >Read More</Link>
          
          </div> 
        </div>

      </article>
      
      <article className="entry">

      <div className="entry-img">
        <img src="./image/blog/singlepublications/cattles.jpg" alt="" className="img-fluid"/>
      </div>

      <h2 className="entry-title">
        <a href="blog-single.html"> Farm Radio Trust is supporting beef cattle Farmers</a>
      </h2>

      <div className="entry-meta">
        <ul>
          <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
          <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">March 18, 2024</time></a></li>
          <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">2 Comments</a></li>
        </ul>
      </div>

      <div className="entry-content">
        <p>
        Farm Radio Trust is supporting beef cattle Farmers in Mzimba district, by providing them a platform through which they are able to share their experiences, and to learn more either from their fellow farmers or from the extension workers.

        As part of equipping the farmers with skills to access information, Farm Radio has partnered with Heifer Malawi, to promote the beef cattle farming, through the use of digital extension such as the production of interactive radio programs, the SMS push service, and the use of the toll free Call Centre Hotlines (8111 on the Airtel Network and 7111 on the Tnm network).

        In the picture, Mlimi Radio's Chekha Chipeta is captured while recording a radio program called 'Tipindule ndi Ulimi wa Ng'ombe za Nyama in Mzimba district. Mlimi Radio is one of the broadcasters that have been commissioned by FRT to air the radio program.

        So far, the beef cattle farmers have testified about the benefits they are getting from the radio program, such as beef cattle management, including linkages to profitable markets.
        </p>
        <div className="read-more">
          <Link to='/news/farmgate'>Read More</Link>
        </div>
      </div>

    </article>
        
        <article className="entry">

        <div className="entry-img">
          <img src="./image/blog/rex.jpg" alt="" className="img-fluid"/>
        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">Malawi Agricultural Productivity and Commercialisation Conference</a>
        </h2>

        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">August 30-31, 2023</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">3 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>
          "Integration of digital platforms is not an option but a must," says Mr. Rex Chapota, one of the panelists at the two-day Malawi Agricultural Productivity and Commercialisation Conference, which ends today, Thursday, August 31 at the Bingu International Convention Centre in Lilongwe.
          Mr. Chapota is also the Global Advisor for Strategy and Growth, and Head of the Pan Africa Program - Farm Radio International.
          "Think about digital services that are viable businesses, essential to spur agriculture commercialization..," argued Mr.Chapota, while he was discussing the Role of Digitisation in Agricultural Commercialisation, under the theme: Diversified Agricultural Value Chains for Improved Trade Balances and Foreign Currency Reserves.
          </p>
          <div className="read-more">
            <a href="blog-single.html">Read More</a>
          </div>
        </div>

      </article>
      <article className="entry">

      <div className="entry-img">
        <img src="./image/blog/geo.jpg" alt="" className="img-fluid"/>
      </div>

      <h2 className="entry-title">
        <a href="blog-single.html">Malawi Agricultural Productivity and Commercialisation Conference</a>
      </h2> 

      <div className="entry-meta">
        <ul>
          <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
          <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">August 30-31, 2023</time></a></li>
          <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">3 Comments</a></li>
        </ul>
      </div>

      <div className="entry-content">
        <p>
        Delegates at the two day Malawi Agricultural Productivity and Commercialisation Conference have been challenged to utilise digitisation as a pathway for scaling up production and productivity, if Malawi is to make meaningful headway with agricultural commercialisation. 
        The call has come from the Chief Executive Officer (CEO) for Farm Radio Trust (FRT) Mr. George Vilili, who made a presentation at the  conference on The Role of Digitisation in Agricultural Commercialisation. 
        The Malawi Agricultural productivity and Commercialisation Conference, which is being held at the Bingu International Convention Centre in Lilongwe, ends today, Thursday, August 31. The theme for the conference is: Diversified Agricultural Value Chains for Improved Trade Balances and Foreign Currency Reserves.</p>
        <div className="read-more">
          <a href="blog-single.html">Read More</a>
        </div>
      </div>

    </article>

          <article className="entry">

            <div className="entry-img">
              <img src="./image/blog/blog-1.jpg" alt="" className="img-fluid"/>
            </div>

            <h2 className="entry-title">
              <a href="blog-single.html">19th National Agriculture Fair</a>
            </h2>

            <div className="entry-meta">
              <ul>
                <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
                <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">August 24, 2023</time></a></li>
                <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">3 Comments</a></li>
              </ul>
            </div>

            <div className="entry-content">
              <p>
              It has been a very busy day today for the Executive Director for Farm Radio Trust, Mr. George Vilili, who has been attending to patrons at the institution's pavilion at this year's 19th National Agriculture Fair. In the picture, Mr. Vilili is explaining to the country's Agriculture Minister, Hon. Sam Kawale, who officially opened the fair today, on some of the products and services Farm Radio is offering. The fair, which is being held under the theme 'Achieving Self-Reliance through Agricultural Commercialisation, will run up to Saturday, 26th August 2023, and is being held at the Trade Fair grounds in the city of Blantyre, Malawi. Farm Radio is showcasing its digital platforms such as the Mlimi Call Centre, the Interactive Voice Response, SMS, Mlimi Radio, interactive radio programming, etc. All this is being done in an effort to compliment government's vision for the Malawi 2063.
              </p>
              <div className="read-more">
                <a href="blog-single.html">Read More</a>
              </div>
            </div>

          </article>

          <article className="entry">

            <div className="entry-img">
            <img src="./image/blog/blog-2.jpg" alt="" className="img-fluid"/>

            {/*<video src={movie}  loop controls/>*/}

            </div>

            <h2 className="entry-title">
              <a href="blog-single.html">FRT and NPC have signed a Memorandum of Understanding (MoU) </a>
            </h2>
          
            <div className="entry-meta">
              <ul>
                <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
                <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">July 23, 2023</time></a></li>
                <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">16 Comments</a></li>
              </ul>
            </div>

            <div className="entry-content">
              <p>
              Farm Radio Trust (FRT) and  The National Planning  Commission (NPC) have signed a Memorandum of Understanding (MoU) to upscale awareness about the Malawi 2063 agenda.
              </p>
              <div className="read-more">
                <a href="blog-single.html">Read More</a>
              </div>
            </div>

          </article>
          <article className="entry">

          <div className="entry-img">

          <img src="./image/blog/blog-3.jpg" alt="" className="img-fluid"/>

          </div>

          <h2 className="entry-title">
            <a href="blog-single.html">Mlimi Radio Launch</a>
          </h2>
          
          <div className="entry-meta">
            <ul>
              <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
              <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">March 25, 2023</time></a></li>
              <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
            </ul>
          </div>

          <div className="entry-content">
            <p>
            Finally, Mlimi Radio, M'godi wa Uthenga, has officially been launched today.officially launched on 25th March 2023 by His Excellency Mr. Matthias Diependael (Minister of the Government of Flanders)
              </p>
            <div className="read-more">
              <a href="blog-single.html">Read More</a>
            </div>
          </div>

        </article>
          
          <article className="entry">

            <div className="entry-img">

              <ReactPlayer width={'100%'}  className='youtubev' url={'https://www.youtube.com/watch?v=8yz6yRFFG-o'} controls={true}   />

            </div>

            <h2 className="entry-title">
              <a href="blog-single.html">Radio as a COVID prevention tool for farmers in Malawi</a>
            </h2>
            
            <div className="entry-meta">
              <ul>
                <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
                <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jal 20, 2020</time></a></li>
                <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
              </ul>
            </div>

            <div className="entry-content">
              <p>
              Radio being used as of the great tools to pass information to the Farmers during the Covid 19 Pandemic
                </p>
              <div className="read-more">
                <a href="blog-single.html">Read More</a>
              </div>
            </div>

          </article>

          <article className="entry">

          <div className="entry-img">

            <ReactPlayer width={'100%'}  className='youtubev' url={'https://youtu.be/a9yxShslXhE'} controls={true}   />         </div>

          <h2 className="entry-title">
            <a href="blog-single.html">Radio as a COVID prevention tool for farmers in Malawi</a>
          </h2>
          
          <div className="entry-meta">
            <ul>
              <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
              <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jal 20, 2020</time></a></li>
              <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
            </ul>
          </div>

          <div className="entry-content">
            <p>
            Radio being used as of the great tools to pass information to the Farmers during the Covid 19 Pandemic
              </p>
            <div className="read-more">
              <a href="blog-single.html">Read More</a>
            </div>
          </div>

        </article>

        <article className="entry">

        <div className="entry-img">

          <ReactPlayer width={'100%'} className='youtubev' url={'https://youtu.be/F4ytP4Cxdbk'} controls={true}   />

        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">Radio as a COVID prevention tool for farmers in Malawi</a>
        </h2>
        
        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jal 20, 2020</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>Farm Radio Trust (FRT), a leading ICT based non-profit making organization in Malawi has been implementing a social accountability project in Chikwawa and Nsanje districts in southern Malawi since 2017, with funding from the Open Society Initiative for Southern Africa (OSISA). Recently, OSISA invited all its grant awardees in Southern Africa to a stakeholder consultative meeting in Maputo, Mozambique. FRT was represented at this meeting by its Chief Executive Officer (CEO), Mr. George Vilili. While there, George caught up with Tim Wise, an accomplished researcher who has done a lot of work for the OSISA Foundation. In this interview, George speaks with Tim about a book he was launching in Maputo, known as.. ‘Eating Tomorrow,’ in which he has documented agricultural investments in Southern Africa, specifically in countries where the OSISA Foundation is implementing social accountability work, such as Malawi where the government is implementing the Farm Input Subsidy Programme (FISP). Tim also tips Farm Radio on how the institution could scale up its social accountability work in Malawi through radio and other ICTs such as the mobile-based platforms.</p>
          <div className="read-more">
            <a href="blog-single.html">Read More</a>
          </div>
        </div>

      </article>

          <div className="blog-pagination">
            <ul className="justify-content-center">
              <li><a href="#">1</a></li>
              <li className="active"><a href="#">2</a></li>
              <li><a href="#">3</a></li>
            </ul>
          </div>

        </div>

        <div className="col-lg-4">

          <div className="sidebar">

            <h3 className="sidebar-title">Search</h3>
            <div className="sidebar-item search-form">
              <form action="">
                <input type="text"/>
                <button type="submit"><i className="bi bi-search"></i></button>
              </form>
            </div>
            <Link to="/signin" ><h5 className="sidebar-title">UPLOAD </h5></Link>

            <h3 className="sidebar-title">Categories</h3>
            <div className="sidebar-item categories">
              <ul>
                <li><a href="#">General <span>(13)</span></a></li>
                <li><a href="#">FRT<span>(6)</span></a></li>
                <li><a href="#">FHL<span>(4)</span></a></li>
                <li><a href="#">MLIMI Radio<span>(3)</span></a></li>

              </ul>
            </div>

            <h3 className="sidebar-title">Recent Posts</h3>
            <div className="sidebar-item recent-posts">
            <div className="post-item clearfix">
                <img src="./image/blog/farmgateprices.png" alt=""/>
                <h4><a href="blog-single.html">Farm Gate Prices</a></h4>
                <time datetime="2020-01-01">April 25, 2024 </time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/singlepublications/cattles.jpg" alt=""/>
                <h4><a href="blog-single.html">FRT Supporting cattle Farmers</a></h4>
                <time datetime="2020-01-01">18 March, 2024 </time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-1.jpg" alt=""/>
                <h4><a href="blog-single.html">19th National Agriculture Fair</a></h4>
                <time datetime="2020-01-01">August 24, 2023 </time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-2.jpg" alt=""/>
                <h4><a href="blog-single.html">FRT And NPC Have Signed A Memo Of Understanding(MoU)</a></h4>
                <time datetime="2020-01-01">July 23, 2023</time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-3.jpg" alt=""/>
                <h4><a href="blog-single.html">Happy Africa Day</a></h4>
                <time datetime="2020-01-01">May 25, 2023</time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-4.jpg" alt=""/>
                <h4><a href="blog-single.html">Trainings</a></h4>
                <time datetime="2020-01-01">Jan 1, 2023</time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-5.jpg" alt=""/>
                <h4><a href="blog-single.html">Mlimi Radio Launch</a></h4>
                <time datetime="2020-01-01">March 25, 2023</time>
              </div>

            </div>

            <h3 className="sidebar-title">Tags</h3>
            <div className="sidebar-item tags">
              <ul>
                <li><a href="#">ICT plartforms</a></li>
                <li><a href="#">mlimi App</a></li>
                <li><a href="#">services</a></li>
                <li><a href="#">Mlimi Radio</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Offices</a></li>
                <li><a href="#">Innovations</a></li>
                <li><a href="#">Studio</a></li>

                <li><a href="#">Tips</a></li>
                <li><a href="#">Marketing</a></li>
              </ul>
            </div>

          </div>

        </div>

      </div>

    </div>
  </section>
    
    </>
  )
}

export default Publications